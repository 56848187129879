<template>
  <v-card :class="operatorClass()" elevation="0">
    <v-data-table
      v-model="selected"
      dense
      :loading="loading"
      :loading-text="$t('loading')"
      :headers="headers"
      :items="plan"
      :item-class="getItemClass"
      :show-select="!operatorView"
      :search="search"
      :footer-props="{
        'items-per-page-options': [20, 40, 60, 80, 100],
        'items-per-page-text': $t('table.per-page')
      }"
      :no-data-text="$t('table.no-data')"
      :items-per-page="100"
      :options.sync="options"
      :server-items-length="total"
      group-by="day"
      @click:row="selectItem"
    >
      <template #top>
        <v-toolbar v-if="toolbarVisible" flat>
          <v-icon
            v-show="!operatorView"
            v-if="
              hasPermission('api.change_order') &&
              selected.length > 0 &&
              !archived
            "
            medium
            @click="updateArchivedFlag(true)"
          >
            mdi-archive
          </v-icon>
          <v-icon
            v-show="!operatorView"
            v-if="
              hasPermission('api.change_order') &&
              selected.length > 0 &&
              archived
            "
            medium
            @click="updateArchivedFlag(false)"
          >
            mdi-restore
          </v-icon>
        </v-toolbar>
      </template>
      <template #[`item.etc`]="{ item }">
        <span v-if="item.status == 'finished' || archived">---</span>
        <span v-else>{{ etc(item) }}</span>
      </template>
      <template #[`item.eta`]="{ item }">
        <span v-if="item.status == 'finished' || archived">---</span>
        <span v-else>{{ eta(item) }}</span>
      </template>
      <template #[`item.status`]="{ item }">
        {{ tStatus(item.status) }}
      </template>
      <template #[`item.due_date`]="{ item }">
        {{ dueDate(item) }}
      </template>
      <template #[`group.header`]="{ items, isOpen, toggle }">
        <th colspan="12" class="day-label secondary">
          <v-icon @click="toggle">
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          <span class="white--text">
            {{ dayLabel(items[0].day) }}
          </span>
        </th>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PlanService from '@/services/PlanService.js';
import planMixin from './planMixin.js';

export default {
  components: {},
  mixins: [planMixin],
  props: {
    machine: {
      type: Object,
      required: true
    },
    epoch: {
      type: Number,
      required: true
    },
    operatorView: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    archived: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      loading: false,
      plan: [],
      selected: [],
      searchTimeout: null,

      draggedRow: null,

      options: {},
      total: 0
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('plan.priority'),
          value: 'order.priority',
          sortable: false
        },
        {
          text: this.$t('plan.status'),
          value: 'status',
          sortable: false
        },
        {
          text: this.$t('plan.client'),
          value: 'order.client_ro.name',
          sortable: false
        },
        {
          text: this.$t('orders.order'),
          value: 'order.order_no',
          sortable: false
        },
        {
          text: this.$t('orders.diecut'),
          value: 'order.die_cut_id',
          sortable: false
        },
        {
          text: this.$t('tools.nesting'),
          value: 'order.die_cut_ro.nesting',
          sortable: false
        },
        {
          text: this.$t('plan.quantity'),
          value: 'quantity',
          sortable: false,
          align: 'right'
        },
        {
          text: this.$t('plan.done'),
          value: 'quantity_done',
          sortable: false,
          align: 'right'
        },
        {
          text: this.$t('orders.field.due_date'),
          value: 'due_date',
          sortable: false
        },
        {
          text: this.$t('plan.etc'),
          value: 'etc',
          sortable: false
        },
        {
          text: this.$t('plan.eta'),
          value: 'eta',
          sortable: false
        }
      ];
    },
    toolbarVisible() {
      return this.hasPermission('api.change_order') && this.selected.length > 0;
    }
  },
  watch: {
    async machine() {
      await this.load();
    },
    async epoch() {
      await this.load();
    },
    search: {
      handler() {
        this.options.page = 1;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.load();
        }, 500);
      }
    },
    options: {
      async handler() {
        await this.load();
      },
      deep: true
    }
  },
  async mounted() {
    await this.load();
  },

  beforeDestroy() {},
  methods: {
    selectItem(item) {
      this.$root.$emit('operator:select', item);
    },
    getItemClass: function (item) {
      return `plan-item plan-item-${item.id} item-${item.status.replace(
        ' ',
        '-'
      )}`;
    },
    async load() {
      this.loading = true;
      try {
        console.log(
          'plan - loading plan for machine:',
          this.machine.name,
          'archived:',
          this.archived
        );
        [this.plan, this.total] = await PlanService.getMachinePlan(
          this.machine.id,
          this.options,
          this.search,
          this.archived
        );

        let h0 = {
          text: '#',
          value: 'index',
          sortable: false
        };
        if (this.operatorView && this.headers[0].value != 'index')
          this.headers.splice(0, 0, h0);

        let cnt = 1;
        this.plan = this.plan.map((pi) => {
          let i = this.transform(pi);
          if (this.operatorView) i.index = cnt++;
          return i;
        }, this);

        console.log('plan:', this.plan);
        if (!this.operatorView) {
          this.$nextTick(() => {
            this.enableDragAndDrop();
          });
        }
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },

    transform(pi) {
      let i = { ...pi };
      i.eta = this.dateFromISO8601(i.eta);
      i.order.due_date = this.dateFromISO8601(i.order.due_date);
      if (!i.eta) {
        i.eta = new Date();
        i.eta.setFullYear(i.eta.getFullYear() + 1);
      }
      i.day = new Date(
        i.eta.getFullYear(),
        i.eta.getMonth(),
        i.eta.getDate()
      ).getTime();
      return i;
    },

    dayLabel(unixMilliseconds) {
      let date = new Date();
      date.setTime(unixMilliseconds);
      return date.toLocaleDateString();
    },
    async updateArchivedFlag(value) {
      console.log('archive(', value, '):', this.selected);
      for (let i of this.selected) {
        i.archived = value;
        try {
          await PlanService.archive(this.machine.id, i);
        } catch (err) {
          console.log('archive planitem error:', err);
        }
      }
      this.$emit('refreshRequired');
      this.selected = [];
    },
    eta(item) {
      if (!item.eta) return '';
      return item.eta.toLocaleString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    etc(item) {
      if (!item.etc) return '';
      return item.etc.split(':').slice(0, 2).join(':');
    },
    dueDate(item) {
      if (!item.order.due_date) return '';
      return item.order.due_date.toLocaleDateString();
    },

    enableDragAndDrop() {
      console.log('enabling drag&drop');
      const rows = document.getElementsByClassName('plan-item');
      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        row.setAttribute('draggable', true);
        row.addEventListener('dragover', this.onDragOver);
        row.addEventListener('dragstart', this.onDragStart);
        row.addEventListener('dragenter', this.onDragEnter);
        row.addEventListener('dragleave', this.onDragLeave);
        row.addEventListener('dragend', this.onDragEnd);
        row.addEventListener('drop', this.onDrop);
      }
    },
    onDragStart(evt) {
      const id = this.extractID(evt.target);
      evt.dataTransfer.setData('text/plain', id);
      evt.dataTransfer.dropEffect = 'move';
      evt.target.style.opacity = 0.5;
      this.draggedRow = evt.target;
    },
    onDragOver(evt) {
      evt.preventDefault();
    },
    onDragEnter(evt) {
      const tr = evt.target.parentNode;
      tr.classList.add('hover');
    },
    onDragLeave(evt) {
      const tr = evt.target.parentNode;
      tr.classList.remove('hover');
    },
    onDragEnd() {
      if (this.draggedRow) {
        this.draggedRow.style.opacity = 1;
        this.draggedRow = null;
      }
    },
    async onDrop(evt) {
      const srcId = evt.dataTransfer.getData('text/plain');

      const tr = evt.target.parentNode;
      const dstId = this.extractID(tr);
      if (srcId == dstId) {
        return;
      }

      const res = await this.$swal({
        html: this.$t('plan.drag-apply-all-machines'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      const srcTr = document.getElementsByClassName(`plan-item-${srcId}`);
      if (srcTr?.length > 0) {
        srcTr[0].style.opacity = 1;
      }

      tr.classList.remove('hover');
      this.draggedRow = null;

      if (res.isDismissed) {
        return;
      }

      this.move(srcId, dstId);
    },
    extractID(elem) {
      const cs = elem.className.split(' ');
      for (const c of cs) {
        if (!c.startsWith('plan-item-')) {
          continue;
        }
        return c.substr('plan-item-'.length);
      }
    },
    async move(id, beforeID) {
      console.log(`move: ${id} before ${beforeID}`);
      this.loading = true;
      try {
        await PlanService.drag(this.machine.id, id, beforeID);
        await this.load();
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
.item-in-production {
  background-color: rgb(102, 211, 120);
}
.item-stopped {
  background-color: lightsteelblue;
}
.item-finished {
  background-color: lightgrey;
}
.item-on-hold {
  background-color: red;
}
.day-label {
  font-weight: bold;
  font-size: 1.1em !important;
}
.hover {
  background-color: rgb(45, 53, 65);
}
</style>
