const mixin = {
  methods: {
    logTransform: function(l) {
      let log = { ...l };
      if (log.timeStart) {
        log.formattedStartTime = this.dateFromISO8601(
          log.timeStart
        ).toLocaleString();
      }

      if (log.timeStop) {
        log.formattedStopTime = this.dateFromISO8601(
          log.timeStop
        ).toLocaleString();
      }

      if (log.timeStart && log.timeStop) {
        log.formattedSpentTime = this.dur(
          Math.floor(
            (Date.parse(log.timeStop) - Date.parse(log.timeStart)) / 1000
          )
        );
      }

      if (log.formattedSpentTime < 0) {
        log.formattedSpentTime = 0;
      }

      return log;
    },
    tLogStatus(status) {
      const key = `plan.statuses.${status}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return status;
    }
  }
};

export default mixin;
