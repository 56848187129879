<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="700" @keydown.esc="onCancel">
      <v-card elevation="0">
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('qc.quality-control') }}
          <v-icon right dark large>
            mdi-check-circle
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="cleaned"
                :label="$t('qc.i-have-cleaned-up-the-workplace-0')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-container fluid>
              <v-radio-group v-model="status">
                <template #label>
                  <div>{{ $t('qc.final-product-quality') }}</div>
                </template>
                <v-radio value="approved">
                  <template #label>
                    <div class="approved">
                      {{ $t('qc.statuses.approved') }}
                    </div>
                  </template>
                </v-radio>
                <v-radio value="conditionally approved">
                  <template #label>
                    <div class="conditionally">
                      {{ $t('qc.statuses.conditionally approved') }}
                    </div>
                  </template>
                </v-radio>
                <v-radio value="rejected">
                  <template #label>
                    <div class="rejected">
                      {{ $t('qc.statuses.rejected') }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-container>
          </v-row>
          <v-row>
            <v-textarea
              v-model="comments"
              rows="2"
              auto-grow
              class="value"
              :rules="[v => (v || '').length > 0 || status == 'approved']"
              shaped
              :label="$t('comments')"
              @focus="keyboardField = 'comments'"
            />
          </v-row>
          <v-row>
            <v-col cols="12">
              <SimpleKeyboard
                :key="keyboardEpoch"
                keyboard-class="qccomments"
                :input="comments"
                :layout="layout"
                @onChange="
                  input => {
                    comments = input;
                  }
                "
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="onCancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" text @click="onSave">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SimpleKeyboard from '@/components/keyboard/SimpleKeyboard';
import keyboardMixin from '@/components/keyboard/keyboardMixin';

export default {
  components: {
    SimpleKeyboard
  },
  mixins: [keyboardMixin],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    net: {
      type: Number,
      required: true
    },
    gross: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialog: this.value,
      cleaned: false,
      status: '',
      comments: ''
    };
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
      this.reset();
    },
    dialog(newValue) {
      this.$emit('input', newValue);
    }
  },

  activated() {},

  methods: {
    reset() {
      this.cleaned = false;
      this.status = '';
      this.comments = '';
    },

    onCancel() {
      this.$emit('close');
    },

    onSave() {
      if (!this.cleaned) {
        this.showError(this, this.$t('qc.work-area-not-cleaned'));
        return;
      }
      if (!this.status.length) {
        this.showError(this, this.$t('qc.quality-of-product-not-declared'));
        return;
      }
      if (this.status != 'approved' && !this.comments.length) {
        this.showError(this, this.$t('qc.comment-required'));
        return;
      }

      this.$emit('save', {
        quantity_net: this.net,
        quantity_gross: this.gross,
        cleaned: this.cleaned,
        status: this.status,
        comments: this.comments
      });
    }
  }
};
</script>

<style lang="sass" scoped>
.approved
    font-size: 22px
    color: green
    weight: bold
.conditionally
    font-size: 22px
    background: yellow
    weight: bold
.rejected
    font-size: 22px
    color: red
    weight: bold
</style>
