var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:_vm.operatorClass(),attrs:{"elevation":"0"}},[_c('v-data-table',{attrs:{"dense":"","loading":_vm.loading,"loading-text":_vm.$t('loading'),"headers":_vm.headers,"items":_vm.plan,"item-class":_vm.getItemClass,"show-select":!_vm.operatorView,"search":_vm.search,"footer-props":{
      'items-per-page-options': [20, 40, 60, 80, 100],
      'items-per-page-text': _vm.$t('table.per-page')
    },"no-data-text":_vm.$t('table.no-data'),"items-per-page":100,"options":_vm.options,"server-items-length":_vm.total,"group-by":"day"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.selectItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.toolbarVisible)?_c('v-toolbar',{attrs:{"flat":""}},[(
            _vm.hasPermission('api.change_order') &&
            _vm.selected.length > 0 &&
            !_vm.archived
          )?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.operatorView),expression:"!operatorView"}],attrs:{"medium":""},on:{"click":function($event){return _vm.updateArchivedFlag(true)}}},[_vm._v(" mdi-archive ")]):_vm._e(),(
            _vm.hasPermission('api.change_order') &&
            _vm.selected.length > 0 &&
            _vm.archived
          )?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.operatorView),expression:"!operatorView"}],attrs:{"medium":""},on:{"click":function($event){return _vm.updateArchivedFlag(false)}}},[_vm._v(" mdi-restore ")]):_vm._e()],1):_vm._e()]},proxy:true},{key:`item.etc`,fn:function({ item }){return [(item.status == 'finished' || _vm.archived)?_c('span',[_vm._v("---")]):_c('span',[_vm._v(_vm._s(_vm.etc(item)))])]}},{key:`item.eta`,fn:function({ item }){return [(item.status == 'finished' || _vm.archived)?_c('span',[_vm._v("---")]):_c('span',[_vm._v(_vm._s(_vm.eta(item)))])]}},{key:`item.status`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.tStatus(item.status))+" ")]}},{key:`item.due_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.dueDate(item))+" ")]}},{key:`group.header`,fn:function({ items, isOpen, toggle }){return [_c('th',{staticClass:"day-label secondary",attrs:{"colspan":"12"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.dayLabel(items[0].day))+" ")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }