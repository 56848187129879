<template>
  <v-card elevation="0">
    <validation-observer :ref="prefixed('observer')">
      <v-card class="pa-5" :class="operatorClass()">
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: true, numeric: true, min_value: 1 }"
              :name="$t('warehouse.item.pcs')"
            >
              <v-text-field
                :ref="prefixed('pcs')"
                v-model="item.pcs"
                class="value"
                :label="$t('warehouse.item.pcs')"
                dense
                :error-messages="errors"
                @keydown.esc="onCancel"
                @focus="keyboardField = prefixed('pcs')"
              />
            </validation-provider>
          </v-col>
          <v-col v-if="keyboardField == prefixed('pcs')" cols="12">
            <SimpleKeyboard
              :keyboard-class="prefixed('keyboard-pcs')"
              :input="item.pcs"
              :layout="numeric"
              @onChange="input => onKeyboardChange('pcs', input)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: true, numeric: true, min_value: 1 }"
              :name="$t('panel.num-of-boxes')"
            >
              <v-text-field
                v-model="item.boxes"
                :label="$t('panel.num-of-boxes')"
                dense
                :error-messages="errors"
                @keydown.esc="onCancel"
                @focus="keyboardField = prefixed('boxes')"
              />
            </validation-provider>
          </v-col>

          <v-col v-if="keyboardField == prefixed('boxes')" cols="12">
            <SimpleKeyboard
              :keyboard-class="prefixed('keyboard-boxes')"
              :input="item.boxes"
              :layout="numeric"
              @onChange="input => onKeyboardChange('boxes', input)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                regex: /^[1-9]+$/,
                required: true
              }"
              :name="$t('tools.params.box_type.name')"
            >
              <v-select
                v-model="item.boxType"
                class="ma-0 pa-0"
                :items="packages"
                item-value="id"
                item-text="description"
                :label="$t('tools.params.box_type.name')"
                :hint="$t('select-hint')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="item.location"
              :label="$t('warehouse.location')"
              dense
              @keydown.esc="onCancel"
              @focus="keyboardField = prefixed('location')"
            />
          </v-col>

          <v-col
            v-if="keyboardField == prefixed('location')"
            cols="12"
            sm="12"
            md="12"
          >
            <SimpleKeyboard
              :key="keyboardEpoch"
              :keyboard-class="prefixed('keyboard-location')"
              :input="item.location"
              :layout="layout"
              @onChange="input => onKeyboardChange('location', input)"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="isDialog" color="blue darken-1" text @click="onCancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSave">
            {{ saveLabel }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-card>
</template>

<script>
import SimpleKeyboard from '@/components/keyboard/SimpleKeyboard';
import keyboardMixin from '@/components/keyboard/keyboardMixin';
import numeric from '@/views/operator/components/NumericLayout.js';
import WarehouseService from '@/services/WarehouseService.js';

export default {
  components: {
    SimpleKeyboard
  },
  mixins: [keyboardMixin],
  props: {
    editedItem: {
      type: Object,
      required: false,
      default: null
    },
    packages: {
      type: Array,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    prefix: {
      type: String,
      default: ''
    }
  },
  data: function() {
    return {
      defaultItem: {
        pcs: '',
        boxes: '',
        total: 0,
        boxType: 0,
        location: ''
      },
      item: Object.assign({}, this.editedItem || this.defaultItem),
      keyboardField: '',
      numeric: numeric
    };
  },
  computed: {
    isDialog() {
      return this.prefix.length > 0;
    },
    saveLabel() {
      return this.editedItem ? this.$t('save') : this.$t('add');
    }
  },
  watch: {
    editedItem: {
      handler(val) {
        this.item = Object.assign({}, val);
      },
      deep: true
    }
  },
  activated() {},
  methods: {
    prefixed(name) {
      const prefixed = `${this.prefix}-${name}`;
      // console.log('prefixed:', prefixed);
      return prefixed;
    },
    onCancel() {
      this.$emit('cancel');
    },
    async onSave() {
      try {
        console.log('refs:', this.$refs);
        const result = await this.$refs[this.prefixed('observer')].validate();
        console.log('onSave.validation:', result);
        if (!result) return;
        await this.saveParcelSet(this.item);
        this.item = Object.assign({}, this.defaultItem);
        this.$refs[this.prefixed('observer')].reset();
      } catch (err) {
        this.showError(this, err);
      }
    },
    async saveParcelSet(item) {
      console.log('save to warehouse');
      let parcelSet = {
        order: this.order.order_no,
        location: item.location,
        pcs: item.pcs,
        boxes: item.boxes,
        boxType: item.boxType
      };

      console.log('item.id:', item.id);
      if (!item.id) {
        parcelSet['status'] = 'prepare';
        let rsp = await WarehouseService.post(parcelSet);
        console.log('added warehouse item: ', rsp);
        // Object.assign(item, rsp.data);

        this.$root.$emit('parcel:added', rsp.data);
      } else {
        parcelSet['id'] = item.id;
        if (item['status']) parcelSet['status'] = item['status'];
        let rsp = await WarehouseService.patch(parcelSet);
        console.log('edited warehouse item: ', rsp);
        this.$root.$emit('parcel:updated', rsp.data);
      }
    },
    onKeyboardChange(field, input) {
      this.$set(this.item, field, input);
      // console.log(field, this.item[field]);
    }
  }
};
</script>

<style></style>
