<template>
  <v-col cols="12" sm="12" md="6">
    <v-card class="pa-3" height="100%" elevation="0">
      <v-card-title class="mb-5">
        <span class="headline">{{ $t('panel.job-details') }}</span>
      </v-card-title>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('customers.customer') }}
        </v-col>
        <v-col class="value">
          {{ client_name }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('orders.diecut') }}
        </v-col>
        <v-col class="value">
          {{ die_cut_name }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('tools.nesting') }}
        </v-col>
        <v-col class="value">
          {{ nesting }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('tools.cardboard') }}
        </v-col>
        <v-col class="value">
          {{ cardboard }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('panel.current-status') }}
        </v-col>
        <v-col class="value">
          {{ planItem.tStatus }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('panel.units') }}
        </v-col>
        <v-col class="value">
          {{ tUnit(machine.efficiency_unit) }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('order-quantity-pcs') }}
        </v-col>
        <v-col class="value">
          {{ planItem.order.quantity }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('panel.status-on-machines') }}
        </v-col>
        <v-col class="value">
          <StatusOnMachines :plan-items="planItems" />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('panel.quantity-requested') }} [{{
            tUnit(machine.efficiency_unit)
          }}]
        </v-col>
        <v-col class="value">
          {{ planItem.quantity }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('panel.quantity-done') }} [{{ tUnit(machine.efficiency_unit) }}]
        </v-col>
        <v-col class="value">
          {{ planItem.quantity_done }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('net') }} [{{ machine.efficiency_unit }}]
        </v-col>
        <v-col class="value">
          {{ totalNet }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('gross') }} [{{ machine.efficiency_unit }}]
        </v-col>
        <v-col class="value">
          {{ totalGross }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('orders.field.description') }}
        </v-col>
        <v-col class="value">
          {{ planItem.order.description }}
        </v-col>
      </v-row>

      <v-row>
        <v-col class="label" cols="4">
          {{ $t('panel.order-comments') }}
        </v-col>
        <v-col class="value">
          {{ planItem.order.comments }}
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import mixin from '../../dashboard/components/plan/planMixin.js';
import StatusOnMachines from '@/views/operator/components/statusOnMachines.vue';
export default {
  components: {
    StatusOnMachines
  },
  mixins: [mixin],
  props: {
    planItem: {
      type: Object,
      required: true
    },
    planItems: {
      type: Array,
      required: true
    },
    totalNet: {
      required: true
    },
    totalGross: {
      required: true
    },
    machine: {
      type: Object,
      required: true
    }
  },
  computed: {
    client_name() {
      return this.planItem.order?.client_ro?.name || '';
    },
    die_cut_name() {
      return this.planItem.order?.die_cut_ro?.name || '';
    },
    nesting() {
      return this.planItem.order?.die_cut_ro?.nesting || '';
    },
    cardboard() {
      return this.planItem.order?.cardboard_thickness || '';
    }
  }
};
</script>

<style></style>
