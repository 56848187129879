<template>
  <v-card :class="operatorClass()" elevation="0">
    <v-tabs v-model="currentTab" fixed-tabs>
      <v-tab v-for="item in tabs" :key="item.key" :href="'#tab-' + item.key">
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-card-title>
        <v-toolbar>
          <v-btn icon class="hidden-xs-only" @click="back">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title>
            <span class="label">{{ $t('orders.field.order_no') }}:</span>
            {{ item.order.order_no }}
          </v-toolbar-title>
          <v-spacer />
          <v-icon small left>
            {{ icon }}
          </v-icon>
          {{ machine.name }}
        </v-toolbar>
      </v-card-title>

      <v-tab-item value="tab-production">
        <v-row>
          <plan-item-info
            :plan-item="item"
            :plan-items="siblingPlanItems"
            :total-gross="totalGross"
            :total-net="totalNet"
            :machine="machine"
          />
          <v-col cols="12" sm="12" md="6">
            <v-card class="pa-3" height="100%" elevation="0">
              <v-card-title class="mb-5">
                <span class="headline">{{ $t('panel.production') }}</span>
              </v-card-title>

              <v-col class="pa-2 mt-3">
                <v-row
                  v-if="
                    item.status != statusInProduction &&
                      item.status != statusCompleted &&
                      item.status != statusOnHold &&
                      canStartJob
                  "
                >
                  <v-col cols="6" sm="6" md="6">
                    <v-select
                      v-model="selectedProcess"
                      :items="processList"
                      :label="$t('panel.current-operation')"
                      :hint="$t('select-hint')"
                    />
                  </v-col>
                </v-row>

                <v-row
                  v-if="
                    item && item.status != statusInProduction && !canStartJob
                  "
                >
                  <v-col>
                    {{ $t('panel.another-job-is-started-on-this-machine') }}
                  </v-col>
                </v-row>

                <validation-observer ref="netgross">
                  <v-row
                    v-if="item && item.status == statusInProduction"
                    class="pb-2"
                  >
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ required: true, numeric: true, min_value: 0 }"
                        :name="$t('net')"
                      >
                        <v-text-field
                          v-model="net"
                          :label="$t('net')"
                          single-line
                          type="number"
                          :error-messages="errors"
                          @focus="keyboardField = 'net'"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col v-if="keyboardField == 'net'" cols="6" sm="6" md="4">
                      <SimpleKeyboard
                        :input="net"
                        :layout="numeric"
                        @onChange="
                          input => {
                            net = input;
                          }
                        "
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="item && item.status == statusInProduction"
                    class="pb-2"
                  >
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="{ required: true, numeric: true, min_value: 0 }"
                        :name="$t('gross')"
                      >
                        <v-text-field
                          v-model="gross"
                          :label="$t('gross')"
                          single-line
                          type="number"
                          :error-messages="errors"
                          @focus="keyboardField = 'gross'"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      v-if="keyboardField == 'gross'"
                      cols="6"
                      sm="6"
                      md="4"
                    >
                      <SimpleKeyboard
                        :input="gross"
                        :layout="numeric"
                        @onChange="
                          input => {
                            gross = input;
                          }
                        "
                      />
                    </v-col>
                  </v-row>
                </validation-observer>
                <v-row>
                  <packager-form
                    v-if="
                      item.machine_type == 'packager' &&
                        item.status == statusInProduction
                    "
                    :packages="packages"
                    :order="item.order"
                  />
                </v-row>

                <v-card-actions class="pb-0">
                  <v-btn
                    v-if="
                      item &&
                        item.status != statusInProduction &&
                        item.status != statusCompleted &&
                        item.status != statusOnHold &&
                        canStartJob
                    "
                    class="mr-2"
                    :disabled="!selectedProcess || startInProgress"
                    color="green"
                    @click="onStart"
                  >
                    {{ $t('panel.start-operation') }}
                  </v-btn>

                  <v-btn
                    v-if="item && item.status == statusInProduction"
                    class="mr-2"
                    color="red"
                    @click="onStop"
                  >
                    {{ $t('panel.stop-operation') }}
                  </v-btn>

                  <quality-control
                    v-model="qcDialog"
                    :net="netF"
                    :gross="grossF"
                    @close="qcDialog = false"
                    @save="doStop"
                  />

                  <v-btn
                    v-if="
                      item &&
                        (item.status == statusStopped ||
                          item.status == statusInProduction)
                    "
                    class="mr-2"
                    :disabled="item.status == 'in production'"
                    color="cyan"
                    @click="askEndProduction"
                  >
                    {{ $t('panel.end-of-production') }}
                  </v-btn>

                  <v-btn
                    v-if="item && item.status == statusCompleted"
                    class="mr-2"
                    color="cyan"
                    @click="askUndoEndProduction"
                  >
                    {{ $t('panel.resume') }}
                  </v-btn>

                  <v-btn
                    v-if="
                      item &&
                        item.status != statusOnHold &&
                        item.status != statusCompleted
                    "
                    class="mr-2"
                    :disabled="item.status == 'in production'"
                    color="red"
                    @click="askOnHold"
                  >
                    {{ $t('panel.put-on-hold') }}
                  </v-btn>

                  <v-btn
                    v-if="item && item.status == statusOnHold"
                    class="mr-2"
                    color="red"
                    @click="askUndoOnHold"
                  >
                    {{ $t('panel.resume') }}
                  </v-btn>
                </v-card-actions>

                <v-row
                  v-if="canStartJob || item.status == statusInProduction"
                  class="mt-3"
                >
                  <v-col>
                    <v-textarea
                      v-model="comments"
                      class="value"
                      rows="2"
                      auto-grow
                      :rules="[
                        v =>
                          (v || '').length <= 500 ||
                          $t('panel.comments-too-long')
                      ]"
                      :label="$t('comments')"
                      @focus="keyboardField = 'comments'"
                    />
                  </v-col>
                  <v-col
                    v-if="keyboardField == 'comments'"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <SimpleKeyboard
                      :key="keyboardEpoch"
                      keyboard-class="keyboard-comments"
                      :input="comments"
                      :layout="layout"
                      @onChange="
                        input => {
                          comments = input;
                        }
                      "
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="label" cols="4">
                    {{ $t('panel.timer') }}:
                  </v-col>
                  <v-col class="value" cols="4">
                    {{ timerContent }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="label" cols="4">
                    {{ $t('panel.current-process') }}:
                  </v-col>
                  <v-col cols="4" class="value">
                    {{ currentProcessLabel() }}
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="label" cols="4">
                    <h2>{{ $t('panel.group-timers') }}:</h2>
                  </v-col>
                </v-row>
                <v-row v-for="group in groups" :key="group.id">
                  <v-col class="label" cols="4">
                    {{ tProcessGroupName(group.name) }}
                  </v-col>
                  <v-col class="value" cols="4">
                    {{ groupTimerFmt(group) }}
                  </v-col>
                </v-row>
              </v-col>

              <v-dialog v-model="messageDialog" max-width="550px">
                <v-card elevation="0">
                  <v-card-title class="headline">
                    {{ errorMessage }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" text @click="closeDialog">
                      {{ $t('ok') }}
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="endProductionDialog" max-width="80%">
                <v-card elevation="0">
                  <v-card-title class="headline">
                    {{ $t('panel.sure-to-finish') }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeEndProduction"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmEndProduction"
                    >
                      {{ $t('ok') }}
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="undoEndProductionDialog" max-width="80%">
                <v-card elevation="0">
                  <v-card-title class="headline">
                    {{ $t('panel.sure-to-resume') }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeUndoEndProduction"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmUndoEndProduction"
                    >
                      {{ $t('ok') }}
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="onHoldDialog" max-width="80%">
                <v-card elevation="0">
                  <v-card-title class="headline">
                    {{
                      $t('panel.are-you-sure-you-want-to-put-this-task-on-hold')
                    }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" text @click="closeOnHold">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="confirmOnHold">
                      {{ $t('ok') }}
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="undoOnHoldDialog" max-width="80%">
                <v-card elevation="0">
                  <v-card-title class="headline">
                    {{ $t('panel.are-you-sure-you-want-to-resume') }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" text @click="closeUndoOnHold">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmUndoOnHold"
                    >
                      {{ $t('ok') }}
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </v-col>
        </v-row>

        <component
          :is="additionalForm"
          v-if="
            item.machine_type == 'packager' && item.status == statusInProduction
          "
          v-bind="additionalFormProps"
          :machine="machine"
        />

        <v-row class="mt-10">
          <span class="ml-10 headline">{{ $t('panel.job-log') }}</span>
        </v-row>
        <v-row class="mt-3">
          <v-col cols="12">
            <v-data-table
              :items="productionLog"
              :headers="headers"
              :sort-by="['timeStart']"
              :sort-desc="[true]"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
                'items-per-page-text': $t('table.per-page')
              }"
              :no-data-text="$t('table.no-data')"
              :options.sync="logOptions"
              :server-items-length="totalLogs"
              dense
            >
              <template #[`item.status`]="{ item }">
                {{ tLogStatus(item.status) }}
              </template>
              <template #[`item.group`]="{ item }">
                {{ tProcessGroupName(item.group_name) }}
              </template>
              <template #[`item.operation`]="{ item }">
                {{ tProcessName(item.group_name, item.operation_name) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="tab-materials">
        <materials :order="item.order" :epoch="materialsEpoch" />
      </v-tab-item>
      <v-tab-item value="tab-attachments">
        <attachments
          ref="attachments"
          :items="attachments"
          permission="api.delete_orderattachment"
          :preview-url="previewUrl"
          disable-upload
          disable-delete
          @error="onError"
          @select="onAttachmentSelected"
          @download-all="onDownloadAll"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ProcessService from '@/services/ProcessService.js';
import PlanService from '@/services/PlanService.js';
import ProductionService from '@/services/ProductionService.js';
import ProductionLogService from '@/services/ProductionLogService.js';
import OrderService from '@/services/OrderService.js';
import ParcelTable from './ParcelTable.vue';
import PackageDefinitionService from '@/services/PackageDefinitionService.js';
import PlanItemInfo from './planItemInfo.vue';
import SimpleKeyboard from '@/components/keyboard/SimpleKeyboard';
import keyboardMixin from '@/components/keyboard/keyboardMixin';
import numeric from '@/views/operator/components/NumericLayout.js';
import PackagerForm from '@/views/operator/components/PackagerForm';
import QualityControl from '@/views/operator/components/QualityControl';
import machineMixin from '@/views/dashboard/components/machines/machineMixin.js';
import operatorMixin from './operatorMixin.js';

export default {
  components: {
    PlanItemInfo,
    materials: () => import('./materialsTab.vue'),
    attachments: () =>
      import('@/views/dashboard/components/files/attachmentList'),
    SimpleKeyboard,
    PackagerForm,
    QualityControl
  },
  mixins: [machineMixin, operatorMixin, keyboardMixin],
  props: {
    planItem: {
      type: Object,
      required: true
    },
    machine: {
      type: Object,
      required: true
    },
    refresh: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      item: Object.assign({}, this.planItem),
      siblingPlanItems: [this.planItem],
      currentTab: 'tab-production',

      pgs: [],
      // processList: [],
      net: '',
      netF: 0.0,
      gross: '',
      grossF: 0.0,
      comments: '',
      selectedProcess: null,
      statusInProduction: 'in production',
      statusStopped: 'stopped',
      statusCompleted: 'finished',
      statusOnHold: 'on hold',
      canStartJob: true,
      startInProgress: false,
      endProductionDialog: false,
      undoEndProductionDialog: false,
      onHoldDialog: false,
      undoOnHoldDialog: false,
      messageDialog: false,
      errorMessage: '',
      timerContent: '---',
      startTime: null,
      timeInterval: null,
      groups: [],
      totalNet: 0,
      totalGross: 0,
      groupTimers: {},
      productionLog: [],
      totalLogs: 0,
      logOptions: {},
      attachments: [],
      previewUrl: '',
      additionalForm: null,
      additionalFormProps: null,
      keyboardField: '',
      qcDialog: false,
      numeric: numeric,
      packages: [],
      materialsEpoch: 0
    };
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('user.user'), value: 'user' },
        { text: this.$t('panel.started-at'), value: 'formattedStartTime' },
        { text: this.$t('panel.stopped-at'), value: 'formattedStopTime' },
        {
          text: this.$t('panel.time-spent'),
          value: 'formattedSpentTime',
          align: 'right'
        },
        { text: this.$t('status'), value: 'status' },
        { text: this.$t('net'), value: 'quantity_net', align: 'right' },
        { text: this.$t('gross'), value: 'quantity_gross', align: 'right' },
        { text: this.$t('panel.operation'), value: 'operation' },
        { text: this.$t('panel.group'), value: 'group' },
        { text: this.$t('comments'), value: 'comments', width: '30%' }
      ];
    },
    tabs() {
      return [
        { key: 'production', name: this.$t('panel.production') },
        { key: 'materials', name: this.$t('materials.header') },
        { key: 'attachments', name: this.$t('attachments.title') }
      ];
    },
    processList() {
      let l = [];
      this.pgs.forEach(group => {
        l.push({
          disabled: true,
          text: this.tProcessGroupName(group.name)
        });
        group.processes.forEach(process => {
          if (this.machine.processes.includes(process.id)) {
            l.push({
              text: this.tProcessName(group.name, process.name),
              value: {
                id: process.id,
                name: process.name,
                group_id: group.id,
                group_name: group.name
              }
            });
          }
        });
      });
      return l;
    }
  },

  watch: {
    planItem: {
      async handler(pi) {
        this.item = Object.assign({}, pi);
        await this.itemUpdated();
      },
      deep: true
    },
    refresh() {
      this.init();
    },
    currentTab(tab) {
      if (tab == 'tab-attachments') {
        this.loadAttachments();
      } else if (tab == 'tab-materials') {
        this.materialsEpoch++;
      }
    }
  },
  async mounted() {
    await this.init();
  },
  async beforeDestroy() {
    await this.clear(true);
  },
  async activated() {
    await this.init();
  },

  methods: {
    async init() {
      this.attachments = [];

      [, , this.packages] = await Promise.all([
        this.loadProcesses(),
        this.loadSiblingPlanItems(this.item),
        PackageDefinitionService.getPackageDefinition()
      ]);

      await this.itemUpdated();

      this.additionalFormProps = { order: this.item.order };
      console.log('additional props', this.additionalFormProps);
      this.additionalForm = ParcelTable;
    },

    async itemUpdated() {
      await Promise.all([
        this.getProductionLog(),
        this.loadSiblingPlanItems(this.item)
      ]);
      this.configureTimer();
      this.selectProcess();
    },

    configureTimer() {
      const pi = this.item;
      this.startTime = null;
      if (pi.status == this.statusInProduction) {
        if (pi.process_started_at) {
          this.startTime = Date.parse(pi.process_started_at);
        } else {
          console.log('configureTimer.fallback');
          // fallback; TODO: remove when all plan items have process_started_at set
          if (this.productionLog.length > 0) {
            const lastItem = this.productionLog[this.productionLog.length - 1];

            if (lastItem.status == this.statusInProduction) {
              this.startTime = Date.parse(lastItem.timeStart);
            }
          }
        }

        if (this.startTime) {
          this.updateTimer();
          if (!this.timeInterval) {
            this.timeInterval = setInterval(this.updateTimer, 1000);
          }
        }
      } else {
        console.log('configureTime.NotInProduction');
      }
    },

    selectProcess() {
      const pi = this.item;
      if (pi.status == this.statusInProduction) {
        if (this.productionLog.length > 0) {
          const log = this.productionLog[this.productionLog.length - 1];
          this.comments = log.comments;
        }

        if (pi.current_process) {
          this.selectedProcess = pi.current_process;
        } else {
          console.log('selectProcess.fallback');
          // fallback; TODO: remove when all plan items have current_process set
          if (this.productionLog.length > 0) {
            const lastItem = this.productionLog[this.productionLog.length - 1];
            this.selectedProcess = this.processList.find(
              o => lastItem.operation == o.value.id
            )?.value;
          }
        }
      }
    },

    async getProductionLog() {
      let productionLog = [];

      [[productionLog, this.totalLogs]] = await Promise.all([
        ProductionLogService.getProductionLog(
          {
            filters: [
              {
                field: 'planItem',
                operation: '=',
                value: this.item.id
              }
            ]
          },
          ''
        ),
        this.loadAttachments()
      ]);

      console.log('productionLog:', productionLog);

      if (productionLog.length > 0) {
        this.totalNet = 0;
        this.totalGross = 0;
        this.groupTimers = {};

        this.productionLog = productionLog.map(log => {
          log = this.logTransform(log);
          this.totalNet += log.quantity_net;
          this.totalGross += log.quantity_gross;

          if (log.status == this.statusStopped) {
            let groupID = log.group_id;

            if (!this.groupTimers[groupID]) {
              this.groupTimers[groupID] = 0;
            }

            this.groupTimers[groupID] +=
              (Date.parse(log.timeStop) - Date.parse(log.timeStart)) / 1000;
          }

          return log;
        });
      }
    },

    async updateTimer() {
      if (!this.startTime) {
        this.clear(true);
        return;
      }
      let elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
      this.timerContent = this.dur(elapsedTime);
    },

    async onStart() {
      if (this.selectedProcess) {
        try {
          this.startInProgress = true;
          let resp = await ProductionService.start(
            this.item.id,
            this.comments,
            this.selectedProcess.id
          );

          console.log('start resp:', resp);

          this.$root.$emit('operator:plan_item_updated', resp.data.plan_item);
          this.$root.$emit('operator:refresh');
          this.clear(false);
          await this.getProductionLog();
        } catch (err) {
          this.showError(this, err);
        } finally {
          this.startInProgress = false;
        }
      }
    },

    clear(clear_comments) {
      this.timerContent = '---';
      this.selectedProcess = null;
      clearInterval(this.timeInterval);
      this.timeInterval = null;
      this.net = '';
      this.gross = '';
      this.startTime = 0;
      if (clear_comments) {
        this.comments = '';
      }
    },

    async onStop() {
      console.log('onStop');
      const result = await this.$refs.netgross?.validate();
      if (!result) return;

      try {
        this.netF = parseFloat(this.net);
        if (isNaN(this.netF)) {
          throw this.$t('panel.invalid-net-value');
        }

        this.grossF = parseFloat(this.gross);
        if (isNaN(this.grossF)) {
          throw this.$t('panel.invalid-gross-value');
        }

        if (this.grossF < this.netF) {
          throw this.$t('panel.gross-cant-be-less-than-net');
        }

        if (this.netF > 0 || this.grossF > 0) {
          console.log('open qcDialog');
          this.qcDialog = true;
        } else {
          await this.doStop(null);
        }
      } catch (err) {
        console.log('err:', err);
        this.showError(this, err);
      }
    },

    async closeDialog() {
      this.messageDialog = false;
    },

    async askUndoEndProduction() {
      this.undoEndProductionDialog = true;
    },

    async closeUndoEndProduction() {
      this.undoEndProductionDialog = false;
    },

    async confirmUndoEndProduction() {
      this.undoEndProductionDialog = false;
      this.resume();
    },

    async resume() {
      try {
        let resp = await ProductionService.resume(this.item.id, this.comments);
        console.log('resume rsp:', resp);
        this.clear(true);
        await this.getProductionLog();

        this.$root.$emit('operator:plan_item_updated', resp.data.plan_item);
        this.$root.$emit('operator:refresh');
      } catch (err) {
        this.showError(this, err);
      }
    },

    async askEndProduction() {
      this.endProductionDialog = true;
    },

    async closeEndProduction() {
      this.endProductionDialog = false;
    },

    async confirmEndProduction() {
      this.endProductionDialog = false;
      try {
        let resp = await ProductionService.finish(this.item.id, this.comments);
        console.log('finish rsp:', resp);
        await this.getProductionLog();
        this.clear(true);

        this.$root.$emit('operator:plan_item_updated', resp.data.plan_item);
        this.$root.$emit('operator:refresh');
        this.$root.$emit('operator:endOfProduction');
      } catch (err) {
        this.showError(this, err);
      }
    },

    async askOnHold() {
      this.onHoldDialog = true;
    },

    async closeOnHold() {
      this.onHoldDialog = false;
    },

    async confirmOnHold() {
      this.onHoldDialog = false;
      try {
        let resp = await ProductionService.onHold(this.item.id, this.comments);
        console.log('onHold rsp:', resp);
        await this.getProductionLog();
        this.clear(true);

        this.$root.$emit('operator:plan_item_updated', resp.data.plan_item);
        this.$root.$emit('operator:refresh');
      } catch (err) {
        this.showError(this, err);
      }
    },

    async askUndoOnHold() {
      this.undoOnHoldDialog = true;
    },

    async closeUndoOnHold() {
      this.undoOnHoldDialog = false;
    },

    async confirmUndoOnHold() {
      this.undoOnHoldDialog = false;
      this.resume();
    },

    back() {
      clearInterval(this.timeInterval);
      this.$root.$emit('operator:list');
    },

    async loadProcesses() {
      this.pgs = await ProcessService.getProcessGroups();
      console.log('pgs:', this.pgs);
      this.pgs.forEach(group => {
        this.groups.push({
          name: this.tProcessGroupName(group.name),
          id: group.id
        });
      });
    },

    async loadSiblingPlanItems(planItem) {
      const options = {
        filters: [
          {
            field: 'order__order_no',
            operation: '=',
            value: planItem.order.order_no
          }
        ],
        page: 1,
        itemsPerPage: 1000,
        sortBy: ['stage'],
        sortDesc: [false]
      };

      [this.siblingPlanItems, _] = await PlanService.getPlanItems(options, '');
      console.log('siblingPlanItems:', this.siblingPlanItems);
    },

    async loadAttachments() {
      console.log('Loading attachments');
      [this.attachments] = await OrderService.listAttachments(this.item.order);
      console.log('attachments:', this.attachments);
    },

    async onAttachmentSelected(attachment) {
      if (!attachment) {
        this.previewUrl = '';
        return;
      }
      let url = await OrderService.downloadAttachment(
        this.item.order,
        attachment.path
      );
      console.log('attachment url: ', url);
      this.previewUrl = url;
    },

    async onDownloadAll() {
      console.log('shipmentAttachments.onDownloadAll');
      const keys = this.attachments.map(at => at.path);
      try {
        const rsp = await OrderService.downloadAttachments(
          this.item.order,
          keys
        );

        let link = document.createElement('a');
        link.href = rsp.url;
        link.setAttribute('download', rsp.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        this.showError(this, err);
      }
    },

    groupTimerFmt(group) {
      let t = this.groupTimers[group.id] || 0;

      if (this.selectedProcess && this.startTime) {
        if (this.selectedProcess.group == group.id) {
          let elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
          t += elapsedTime;
        }
      }

      let ft = this.dur(Math.floor(t));
      switch (group.name) {
        case 'setup':
          return `${ft} / ${this.machine.setup_time}`;
        case 'production': {
          let planned = this.dur(
            Math.floor(this.item.order.quantity / this.machine.efficiency)
          );
          return `${ft} / ${planned}`;
        }
        default:
          return ft;
      }
    },

    dur(duration) {
      // const duration = Math.floor((Date.parse(item.timeStop) - Date.parse(item.timeStart)) / 1000)
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor(duration / 60) % 60;
      const seconds = duration % 60;

      let s =
        hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0') +
        ':' +
        seconds.toString().padStart(2, '0');

      return s;
    },

    currentProcessLabel() {
      if (!this.selectedProcess) {
        return this.$t('panel.current-process-none');
      }
      return this.tProcessName(
        this.selectedProcess.group_name,
        this.selectedProcess.name
      );
    },

    async doStop(report) {
      console.log('doStop');

      try {
        console.log('calling stop');
        let resp = await ProductionService.stop(
          this.item.id,
          this.comments,
          this.netF,
          this.grossF,
          report
        );
        console.log('stop rsp:', resp);
        await this.getProductionLog();
        this.clear(true);

        this.canStartJob = true;
        this.item.status = resp.data.status;

        this.$root.$emit('operator:plan_item_updated', resp.data.plan_item);
        this.$root.$emit('operator:refresh');
        this.qcDialog = false;
      } catch (err) {
        console.log('err:', err);
        this.showError(this, err);
      }
    },

    onError(err) {
      this.showError(this, err);
    }
  }
};
</script>

<style lang="sass">
.operator
  .v-data-table__wrapper > table > tbody > tr > td
    font-size: 120%
  .v-data-table__wrapper > table > thead > tr > th
    font-size: 90%
  .value
    font-size: 120%
  .label
    font-size: 120%
    font-weight: bold
    text-align: right
  .v-text-field input
    font-size: 120%
  .v-label.v-label
    font-size: 120%
  .v-btn.v-size--default
  input
    font-size: 120%
  .v-alert__content
    font-size: 120%
</style>
