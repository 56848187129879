<template>
  <div>
    <table>
      <thead>
        <tr>
          <th class="bordered left-align no-left-padding">
            {{ $t('machines.type') }}
          </th>
          <th class="bordered">{{ $t('machines.symbol') }}</th>
          <th class="bordered right-align">
            {{ $t('panel.quantity-requested') }}
          </th>
          <th class="right-align">{{ $t('panel.quantity-done') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in planItems" :key="item.id">
          <td class="bordered left-align no-left-padding">
            {{ tType(item.machine_ro.type) }}
          </td>
          <td class="bordered">{{ item.machine_ro.symbol }}</td>
          <td class="bordered right-align">{{ item.quantity }}</td>
          <td class="right-align">{{ item.quantity_done }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import MachineMixin from '@/views/dashboard/components/machines/machineMixin';
export default {
  name: 'OrderStatusOnMachines',
  mixins: [MachineMixin],
  props: {
    planItems: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

.bordered {
  border-right: 1px solid #000;
}

td,
th {
  padding: 8px 12px;
}

.no-left-padding {
  padding-left: 0;
}

table {
  border-collapse: collapse;
}
</style>
