export default {
  methods: {
    tUnit: function(unit) {
      const key = `units.${unit}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return unit;
    },
    tStatus(status) {
      const key = `plan.statuses.${status}`;
      if (this.$te(key)) {
        return this.$t(key);
      }
      return status;
    }
  }
};
